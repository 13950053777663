import { keyframes } from "@emotion/core";
import styled from "@emotion/styled";
import { settings } from "boostly-ui2";
const grow = keyframes`
  0% {
    transform: scale(0.75);
  }
`;

const Textarea = styled("textarea")`
  height: 130px;
  padding: 0.7em;
  font-size: 1em;
  background-color: #fff;
  animation: ${grow} 0.75s ease;
  resize: none;
  border-radius: 0.5em;
  border: none;
  transition: ease 0.5s;
  &:focus {
    border: solid 1px ${settings.colors.dusk};
  }
  width: 100%;
  max-width: 450px;
  outline: none;
`;

export default Textarea;
