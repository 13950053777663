import React from "react";
import { Text } from "boostly-ui2";
import { css } from "@emotion/core";

const Title = ({ size = 2, children, ...rest }) => (
  <Text.title
    color="white"
    size={size}
    css={css`
      letter-spacing: 0.07em;
    `}
    {...rest}
  >
    {children}
  </Text.title>
);

export default Title;
