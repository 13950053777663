import React from "react"
import { Box } from "boostly-ui2"
import { makeGradientCss } from "../style/utils"
import { css } from "@emotion/core"
const Gradient = ({
  startHex,
  endHex,
  degree,
  css: _css = ``,
  children,
  ...rest
}) => (
  <Box
    css={css`
      ${_css} ${makeGradientCss({ startHex, endHex, degree })}
    `}
    {...rest}
  >
    {children}
  </Box>
)

export default Gradient
