import React from "react"
import { Box } from "boostly-ui2"
import Gradient from "./Gradient"

const Card = ({ gradient = {}, children }) => (
  <Gradient
    startHex={gradient.from}
    endHex={gradient.to}
    degree={-45}
    opacity=".9"
    borderRadius="10px"
    overflow="hidden"
  >
    <Box p={"28px"}>{children}</Box>
  </Gradient>
)

export default Card
