import React from "react"
import PropTypes from "prop-types"
import { css } from "@emotion/core"
import { Box, Row, Col, Badge, LoadFont, settings } from "boostly-ui2"
import "../../style/global.css"
import Logo from "../Logo"

const Copyright = () => (
  <Badge bg={`rgba(0, 0, 0, 0.05)`}>
    <Row
      is={`a`}
      x
      y
      href={`https://www.boostly.com`}
      target={`_blank`}
      style={{ textDecoration: `none` }}
    >
      <span role={`img`} aria-label={`fire`} style={{ paddingRight: "2.5px" }}>
        🔥
      </span>
      <Box is="span" color={settings.colors.midnight}>
        by
      </Box>
      <Box px={1} pt={`4px`}>
        <Logo />
      </Box>
    </Row>
  </Badge>
)

const Layout = ({ bgShape, children }) => (
  <Col
    minHeight={`100vh`}
    p={3}
    css={css`
      background-image: url("${bgShape}");
      background-repeat: no-repeat;
      background-position: center top;
    `}
  >
    <LoadFont />
    <Box maxWidth={`450px`} m={`0 auto`}>
      {children}
    </Box>
    <Box textAlign={`center`} my={3}>
      <Copyright />
    </Box>
  </Col>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
