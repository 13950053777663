import React from "react";

const Logo = ({ size = "20px" }) => (
  <img
    height={size}
    src={require("../images/wordmark-black.svg")}
    alt="Boostly Logo"
  />
);

export default Logo;
