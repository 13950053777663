import React from "react"
import { Row, Button, Box } from "boostly-ui2"
import { update } from "../api"
import { getState } from "../state"

const ReviewButton = ({ type, placeId }) => {
  const reportOptIn = () => {
    const { reviewId } = getState()

    update({
      reviewId,
      review: { optedToReviewOnGoogle: true },
    })
  }
  return (
    <a
      href={`https://search.google.com/local/writereview?placeid=${placeId}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      <Button.second onClick={reportOptIn}>
        <Row y x>
          <Box pr={3}>
            <img
              src={require("../images/google.svg")}
              height="25px"
              alt="google logo"
              style={{ display: "block" }}
            />
          </Box>
          Review us on Google
        </Row>
      </Button.second>
    </a>
  )
}

export default ReviewButton
