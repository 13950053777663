import React from "react"
import { css } from "@emotion/core"
import { Box, settings, Text } from "boostly-ui2"

const Divider = ({ children }) => (
  <Box
    width="100%"
    height="10px"
    borderBottom={`1px solid ${settings.colors.midnight}`}
    textAlign="center"
    py={3}
    opacity=".6"
  >
    <Box
      is="span"
      fontSize="25px"
      bg="white"
      display="inline-block"
      px={5}
      css={css`
        border-radius: 10px;
      `}
    >
      <Text>{children}</Text>
    </Box>
  </Box>
)

export default Divider
