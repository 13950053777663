import React, { useState } from "react"
import { Row, Box, Text } from "boostly-ui2"
import { css, keyframes } from "@emotion/core"

const notSelected = (index, selectedIndex) => {
  if (selectedIndex < 0) return false
  return index !== selectedIndex
}

const grow = keyframes`
  0% {
    transform: scale(0.75);
  }
`

const EmojiMenu = ({ children }) => {
  const [selectedIndex, setIndex] = useState(-1)
  const [Poor, Satisfactory, Amazing] = [`😑`, `🙂`, `😍`].map((emoji, i) => {
    return ({ onSelect }) => (
      <Box
        pt={`9px`}
        px={2}
        onClick={e => {
          e.preventDefault()
          onSelect && onSelect(i + 1)
          setIndex(i)
        }}
        key={emoji}
        opacity={notSelected(i, selectedIndex) ? 0.3 : 1}
        css={css`
          cursor: pointer;
          transition: 0.25s ease;
        `}
      >
        <Text size={`2.8em`}>
          <span
            role={`img`}
            aria-label={[`poor`, `satisfactory`, `amazing`][i]}
          >
            {emoji}
          </span>
        </Text>
      </Box>
    )
  })
  return (
    <Row
      x
      bg={`white`}
      borderRadius={`10px`}
      space={`evenly`}
      css={css`
        animation: ${grow} 0.75s ease;
      `}
    >
      {children({
        Poor,
        Satisfactory,
        Amazing,
      })}
    </Row>
  )
}

export default EmojiMenu
