import React from "react"
import { css, keyframes } from "@emotion/core"
import { Box } from "boostly-ui2"

const grow = keyframes`
  0% {
    transform: scale(0.75);
  }
`
const Underscore = () => (
  <Box
    w={`125px`}
    height={`4px`}
    bg={`white`}
    my={`28px`}
    css={css`
      animation: ${grow} 0.75s ease;
    `}
  />
)

export default Underscore
